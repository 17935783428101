<template>
  <div>
    <v-progress-circular
      size="40"
      width="3"
      indeterminate
      color="primary"
      class="align-self-start mb-5"
      v-if="remainingTestsLoader"
    ></v-progress-circular>

    <v-expansion-panels class="align-self-start" id="remaining" v-else>
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-2">
          <div class="d-flex align-center">
            <v-icon
              class="mr-4"
              :color="`${
                remainingTests.totalQuantity < remainingTests.totalAlarm
                  ? 'error'
                  : 'success'
              }`"
              >mdi-information-outline</v-icon
            >

            <div
              class="body-1"
              :class="{
                'success--text':
                  remainingTests.totalQuantity >= remainingTests.totalAlarm,
                'error--text':
                  remainingTests.totalQuantity < remainingTests.totalAlarm,
              }"
            >
              Available Tests: {{ remainingTests.totalQuantity }}
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div>
            <ul>
              <li
                class="mb-1"
                :class="{
                  'success--text':
                    testType.remainingQuantity >= testType.alarmQuantity,
                  'error--text':
                    testType.remainingQuantity < testType.alarmQuantity,
                }"
                v-for="testType in remainingTests.alarmQuantities"
                :key="testType.medTestId"
              >
                {{ testType.medTestName }}: {{ testType.remainingQuantity }}
              </li>
            </ul>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      remainingTests: (state) => state.captain.remainingTests,
      remainingTestsLoader: (state) => state.captain.remainingTestsLoader,
    }),
  },
};
</script>

<style lang="scss" scoped>
#remaining {
  width: 400px !important;

  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
}
</style>