import Captain from '@/views/captain/Captain.vue'
import TestList from '@/views/test-list/TestList.vue'
import Transactions from '@/views/transactions/Transactions.vue'
import NewTest from '@/views/captain/NewTest.vue'

export default [
    {
        path: '/captain',
        component: Captain,
        meta: { requiresAuth: true, role: 'captain' }
    },
    {
        path: '/captain/test-list',
        component: TestList,
        meta: { requiresAuth: true, role: 'captain' }
    },
    {
        path: '/captain/transactions',
        component: Transactions,
        meta: { requiresAuth: true, role: 'captain' }
    },
    {
        path: '/captain/new-test',
        component: NewTest,
        meta: { requiresAuth: true, role: 'captain' }
    },
]