import axios from "axios";

export default {
    state: {
        inventory: {
            results: [],
            rowCount: 0
        },
    },
    mutations: {
        getInventory(state, inventory) {
            state.inventory = inventory;
        },
        clearShipownerState(state) {
            state.inventory = {
                results: [],
                rowCount: 0
            };
        }
    },
    actions: {
        async getInventory({ commit }, params) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/ClientAdmin/GetRemainingTests`, {
                    params: {
                        pageSize: params.itemsPerPage,
                        pageNo: params.page,
                        ...(params.sortBy[0] && { sortBy: params.sortBy[0] }),
                        ...(params.sortDesc[0] && { dir: "desc" }),
                        ...(params.search && { search: params.search }),
                    }
                });

                res.data.results = res.data.results.map(inventory => {
                    return {
                        ...inventory,
                        alarmQuantities: inventory.alarmQuantities.reduce((a, v) => ({ ...a, [v.medTestId]: v }), {})
                    }
                });

                commit("getInventory", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
    },
}
