<template>
  <div class="component-wrapper d-flex flex-column">
    <v-progress-circular
      size="120"
      width="5"
      indeterminate
      color="primary"
      class="ma-auto"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
