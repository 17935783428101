import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Login from '@/views/Login'
import ForgotPassword from '@/views/ForgotPassword'

import captainRoutes from "@/router/captain";
import shipownerRoutes from "@/router/shipowner";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch(err => {
      if (err.name != "NavigationDuplicated") {
        throw err;
      }
    })
};

const routes = [
  {
    path: '/login',
    component: Login,
    meta: { requiresUnauth: true }
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    meta: { requiresUnauth: true }
  },
  ...captainRoutes,
  ...shipownerRoutes,
  {
    path: "*",
    redirect: '/captain'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const roles = ["captain", "shipowner"]

router.beforeEach((to, from, next) => {
  //AUTHENTICATED ROUTES
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next("/login");
    } else {
      //if role is not supported then logout and navigate to login
      if (!roles.includes(store.state.auth.role)) {
        store.dispatch('logout');
        next('/login');
      }
      else {
        if (to.meta.role == store.state.auth.role) { //if(role is the same with the meta tag proceed)
          next()
        } else {
          next(`/${store.state.auth.role}`); //else safely(cause of the above check) navigate him to his role 
        }
      }
    }
  }
  //UN-AUTHENTICATED ROUTES
  else if (to.matched.some(record => record.meta.requiresUnauth)) {
    if (store.getters.isLoggedIn) {
      next("/");
    } else {
      next()
    }
  }
  //DEFAULT
  else {
    next()
  }
})

export default router
