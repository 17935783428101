<template>
  <v-app id="dn2me">
    <Navigation v-if="isLoggedIn && !loader" />

    <v-main>
      <InitLoader v-if="loader" />

      <router-view v-else />

      <div
        class="
          px-5
          py-1
          rounded
          banner-wrapper
          d-flex
          flex-column
          align-center
          grey
          lighten-5
        "
        v-if="banner"
      >
        <img
          src="@/assets/healthnews.svg"
          class="banner-logo mb-1 cursor-pointer"
          @click="onOpenBannerLink"
        />

        <div
          class="subtitle-1 font-weight-medium primary--text cursor-pointer"
          @click="onOpenBannerLink"
        >
          ShipCare - News
        </div>

        <img
          class="banner-img my-1 rounded"
          :src="banner.image"
          @click="onOpenBannerLink"
        />

        <div class="body-2 link" @click="onOpenBannerLink">
          {{ banner.title }}
        </div>
      </div>
    </v-main>

    <v-snackbar
      v-model="snackbar.open"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      <div class="d-flex align-center">
        <div class="body-2 mr-2">{{ snackbar.text }}</div>
        <v-btn
          text
          small
          class="ml-auto"
          @click="
            toggleSnackbar({
              open: false,
            })
          "
          >Close</v-btn
        >
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import InitLoader from "@/components/InitLoader";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";

export default {
  components: {
    Navigation,
    InitLoader,
  },
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      token: (state) => state.auth.token,
      snackbar: (state) => state.base.snackbar,
      banner: (state) => state.base.banner,
    }),
  },
  async created() {
    //====== AXIOS INTERCEPTOR ======
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          await this.logout();
          this.$router.push({ path: "/login" });
        }

        throw error;
      }
    );

    if (this.isLoggedIn) {
      //====== ADD AUTH HEADER ======
      axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
    }

    this.loader = true;

    try {
      await this.init();
      this.loader = false;
    } catch (e) {
      if (e.response.status == 401) {
        this.loader = false;
      } else {
        this.toggleSnackbar({
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });
      }
    }
  },
  methods: {
    ...mapActions(["logout", "init"]),
    ...mapMutations(["toggleSnackbar"]),
    onOpenBannerLink() {
      window.open(this.banner.link, "_blank");
    },
  },
};
</script>

<style lang="scss">
//VUETIFY OVERRIDES
.v-btn:not(.v-btn--icon),
.v-tab {
  letter-spacing: normal !important;
  text-transform: none !important;
  font-weight: 400 !important;
}

*:not(.v-icon),
*:not(.v-icon):before,
*:not(.v-icon):after {
  letter-spacing: normal !important;
}

.v-main__wrap {
  display: flex;
  flex-direction: column;
}

.v-tooltip__content {
  max-width: 300px !important;
}

//GLOBAL STYLES
.component-wrapper {
  flex-grow: 1;
  position: relative;
  padding: 20px;
}

.link {
  cursor: pointer;
  color: var(--v-primary-base);
  transition: all 0.2s;

  &:hover {
    text-decoration: underline;
    color: var(--v-primary-lighten1);
  }
}

.card {
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.v-data-table.clickable {
  tr {
    cursor: pointer !important;
  }
}

.scrollable {
  flex: 1 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  height: 0px;
}

.header {
  position: relative;

  .back-btn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.pagination-wrapper {
  max-width: 80%;
  align-self: center;
}

.dialog-wrapper {
  height: 100%;

  &.scrollable-dialog {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 600px) {
      height: 80vh;

      &.small {
        height: 60vh;
      }
    }
  }
}

.banner-wrapper {
  overflow: hidden;
}

.banner-logo {
  height: 30px;
}

.banner-img {
  height: 80px;
  cursor: pointer;
}
</style>
