<template>
  <div class="component-wrapper pt-12">
    <v-container>
      <div class="d-flex justify-center mb-5">
        <RemainingTests />
      </div>

      <v-row>
        <v-col cols="12" md="4">
          <div
            class="
              primary
              d-flex
              flex-column
              align-center
              white--text
              rounded
              pa-5
              card
            "
            @click="$router.push({ path: '/captain/test-list' })"
          >
            <v-icon x-large color="white" class="mb-5"
              >mdi-format-list-numbered</v-icon
            >

            <div class="text-h6">Test List</div>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div
            class="
              primary
              d-flex
              flex-column
              align-center
              white--text
              rounded
              pa-5
              card
            "
            @click="$router.push({ path: '/captain/transactions' })"
          >
            <v-icon x-large color="white" class="mb-5"
              >mdi-wallet-travel</v-icon
            >

            <div class="text-h6">Transactions</div>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div
            class="
              primary
              d-flex
              flex-column
              align-center
              white--text
              rounded
              pa-5
              card
            "
            @click="$router.push({ path: '/captain/new-test' })"
          >
            <v-icon x-large color="white" class="mb-5">mdi-plus</v-icon>

            <div class="text-h6">New Test</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import RemainingTests from "@/views/captain/RemainingTests";

export default {
  components: {
    RemainingTests,
  },
};
</script>

<style lang="scss" scoped>
</style>