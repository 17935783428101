var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"component-wrapper d-flex flex-column"},[_c('div',{staticClass:"header text-center d-flex justify-center mb-8"},[_c('v-btn',{staticClass:"back-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('div',{staticClass:"text-h6"},[_vm._v("Test Inventory")])],1),_c('v-text-field',{staticClass:"flex-grow-0 mr-auto mb-5",attrs:{"outlined":"","dense":"","label":"Search","prepend-inner-icon":"mdi-magnify","clearable":"","hint":"Search ship","persistent-hint":"","readonly":_vm.loader,"loading":_vm.loader},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}}),_c('div',{staticClass:"scrollable mb-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":!_vm.loader ? _vm.inventory.results : [],"loading":_vm.loader,"hide-default-footer":"","server-items-length":_vm.inventory.rowCount,"options":_vm.options},on:{"update:options":_vm.onGetInventory},scopedSlots:_vm._u([_vm._l((_vm.testTypes),function(testType){return {key:("item.alarmQuantities." + (testType.itemKey) + ".remainingQuantity"),fn:function(ref){
        var item = ref.item;
return [(
            item &&
            item.alarmQuantities &&
            item.alarmQuantities[testType.itemKey]
          )?_c('div',{key:testType.itemKey,class:{
            'error--text':
              item.alarmQuantities[testType.itemKey].remainingQuantity <
              item.alarmQuantities[testType.itemKey].alarmQuantity,
            'success--text':
              item.alarmQuantities[testType.itemKey].remainingQuantity >=
              item.alarmQuantities[testType.itemKey].alarmQuantity,
          }},[_vm._v(" "+_vm._s(item.alarmQuantities[testType.itemKey].remainingQuantity)+" ")]):_vm._e()]}}}),{key:"item.totalQuantity",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",class:{
            'error--text': item.totalQuantity < item.totalAlarm,
            'success--text': item.totalQuantity >= item.totalAlarm,
          }},[_vm._v(" "+_vm._s(item.totalQuantity)+" ")])]}}],null,true)})],1),_c('div',{staticClass:"pagination-wrapper"},[(_vm.inventory.pageCount)?_c('v-pagination',{attrs:{"length":_vm.inventory.pageCount,"disabled":_vm.loader},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }