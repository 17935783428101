var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white dialog-wrapper scrollable-dialog"},[_c('div',{staticClass:"primary pa-5 header"},[_c('v-btn',{staticClass:"back-btn",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"text-h6 white--text text-center"},[_vm._v("Test Details")])],1),(_vm.loader)?_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"size":"120","width":"5","indeterminate":"","color":"primary"}}):[_c('div',{staticClass:"pa-5 scrollable"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" Name: "+_vm._s(_vm.testData.firstName)+" "+_vm._s(_vm.testData.lastName)+" ")]),_c('div',{staticClass:"body-1 mb-2"},[_vm._v("Rank: "+_vm._s(_vm.testData.crewRankName))]),(_vm.testData.genderName)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" Gender: "+_vm._s(_vm.testData.genderName)+" ")]):_vm._e(),(_vm.testData.genderName)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" Nationality: "+_vm._s(_vm.testData.nationalityName)+" ")]):_vm._e(),_c('div',{staticClass:"body-1 mb-2"},[_vm._v("Test Type: "+_vm._s(_vm.testData.medTestName))]),_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" Test Date: "+_vm._s(_vm.dayjs(_vm.testData.onDate).format("DD/MM/YYYY HH:mm"))+" ")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"body-1 mr-1"},[_vm._v("Result:")]),_c('div',{staticClass:"body-1 font-weight-medium",class:{
                'red--text': _vm.testData.result == 'positive',
                'green--text': _vm.testData.result == 'negative',
              }},[_vm._v(" "+_vm._s(_vm.testData.result)+" ")])])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},_vm._l((_vm.questionnaire.results),function(question,index){return _c('div',{key:question.symptomTypeId,class:{
              'mb-5': index < _vm.questionnaire.results.length - 1,
            }},[_c('div',{staticClass:"subtitle-1 font-weight-bold mb-3"},[_vm._v(" "+_vm._s(question.symptomTypeText)+" ")]),_vm._l((question.allSymptoms),function(answer,index){return _c('div',{key:answer.symptomId,staticClass:"d-flex flex-column align-start"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",class:{
                  'mb-3': index < question.allSymptoms.length - 1,
                },attrs:{"label":answer.symptomText,"value":answer.symptomId,"input-value":_vm.testData.positiveSymptomIds,"readonly":"","ripple":false,"dense":"","hide-details":""}})],1)})],2)}),0)],1)],1),_c('div',{staticClass:"pa-3 align-self-center d-flex justify-center"},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v("Close")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }