import axios from "axios";

export default {
    state: {
        tests: {
            results: [],
            rowCount: 0
        },
        transactions: {
            results: [],
            rowCount: 0
        },
    },
    mutations: {
        getTests(state, tests) {
            state.tests = tests;
        },
        getTransactions(state, transactions) {
            state.transactions = transactions;
        },
        clearSharedState(state) {
            state.tests = {
                results: [],
                rowCount: 0
            };

            state.transactions = {
                results: [],
                rowCount: 0
            };
        }
    },
    actions: {
        async getTests({ commit, rootState }, params) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/${rootState.auth.role == 'captain' ? 'Ship' : 'ClientAdmin'}/GetTestApplications`, {
                    params: {
                        pageSize: params.itemsPerPage,
                        pageNo: params.page,
                        ...(params.sortBy[0] && { sortBy: params.sortBy[0] }),
                        ...(params.sortDesc[0] && { dir: "desc" }),
                        ...(params.search && { search: params.search }),
                        ...(params.medTestId && { medTestId: params.medTestId }),
                        ...(params.result && { result: params.result }),
                        ...(params.ship && { shipId: params.ship }),
                        ...(params.fleet && { fleetName: params.fleet }),
                    }
                });

                commit("getTests", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getTest({ commit, rootState }, id) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/${rootState.auth.role == 'captain' ? 'Ship' : 'ClientAdmin'}/GetTestApplication/${id}`);

                return res.data;
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getTransactions({ commit, rootState }, params) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/${rootState.auth.role == 'captain' ? 'Ship' : 'ClientAdmin'}/GetTransactions`, {
                    params: {
                        pageSize: params.itemsPerPage,
                        pageNo: params.page,
                        ...(params.sortBy[0] && { sortBy: params.sortBy[0] }),
                        ...(params.sortDesc[0] && { dir: "desc" }),
                        ...(params.medTestId && { medTestId: params.medTestId }),
                        ...(params.transactionTypeId && { transactionTypeId: params.transactionTypeId }),
                        ...(params.ship && { shipId: params.ship }),
                        ...(params.fleet && { fleetName: params.fleet }),
                    }
                });

                commit("getTransactions", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
    },
}