<template>
  <div class="white">
    <div class="header primary text-center pa-4">
      <div class="text-h6 white--text">New Test Transaction</div>
    </div>

    <form class="pa-5" @submit.prevent="submit">
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formattedDate"
            dense
            prepend-inner-icon="mdi-calendar"
            outlined
            label="Transaction Date *"
            class="mb-1"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker
          v-model="date"
          @change="dateMenu = false"
          :max="new Date().toISOString().substr(0, 10)"
        ></v-date-picker>
      </v-menu>

      <v-select
        dense
        outlined
        label="Transaction Type *"
        class="mb-1"
        v-model="transactionType"
        :items="transactionTypes"
        item-value="itemKey"
        item-text="itemValue"
      ></v-select>

      <v-select
        dense
        outlined
        label="Test Type *"
        class="mb-1"
        v-model="testType"
        :items="testTypes"
        item-value="itemKey"
        item-text="itemValue"
        :error-messages="error ? 'There are no tests available' : ''"
        @change="error = false"
      ></v-select>

      <v-text-field
        dense
        outlined
        label="Test Quantity *"
        class="mb-1"
        type="number"
        v-model="quantity"
        :error="error"
      ></v-text-field>

      <v-textarea
        dense
        outlined
        label="Comments"
        class="mb-4"
        no-resize
        v-model="comments"
      ></v-textarea>

      <div class="d-flex justify-center">
        <v-btn
          color="error"
          class="mr-2"
          depressed
          @click="$emit('closeDialog')"
          >Cancel</v-btn
        >

        <v-btn
          color="success"
          depressed
          :disabled="$v.$invalid"
          type="submit"
          :loading="loader"
          >Submit</v-btn
        >
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      dateMenu: false,
      date: null,
      transactionType: null,
      testType: null,
      quantity: null,
      comments: null,
      loader: false,
      error: false,
    };
  },
  validations: {
    date: { required },
    transactionType: { required },
    testType: { required },
    quantity: { required },
  },
  computed: {
    ...mapState({
      transactionTypes: (state) => state.base.lists.transactionTypes,
      testTypes: (state) => state.base.lists.testTypes,
    }),
    formattedDate() {
      return this.date ? this.dayjs(this.date).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    ...mapActions(["newTransaction"]),
    async submit() {
      try {
        this.error = false;
        this.loader = true;

        const time = this.dayjs().format("HH:mm");

        await this.newTransaction({
          onDate: this.dayjs.utc(`${this.date} ${time}`).toISOString(),
          transactionTypeId: this.transactionType,
          medTestId: this.testType,
          quantity: this.quantity,
          comments: this.comments,
        });

        this.$emit("closeDialog");
        this.$emit("transactionAdded");
      } catch (e) {
        if (e.response.data.error.errorCode) {
          this.error = true;
        }
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>