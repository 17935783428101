var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.remainingTestsLoader)?_c('v-progress-circular',{staticClass:"align-self-start mb-5",attrs:{"size":"40","width":"3","indeterminate":"","color":"primary"}}):_c('v-expansion-panels',{staticClass:"align-self-start",attrs:{"id":"remaining"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":("" + (_vm.remainingTests.totalQuantity < _vm.remainingTests.totalAlarm
                ? 'error'
                : 'success'))}},[_vm._v("mdi-information-outline")]),_c('div',{staticClass:"body-1",class:{
              'success--text':
                _vm.remainingTests.totalQuantity >= _vm.remainingTests.totalAlarm,
              'error--text':
                _vm.remainingTests.totalQuantity < _vm.remainingTests.totalAlarm,
            }},[_vm._v(" Available Tests: "+_vm._s(_vm.remainingTests.totalQuantity)+" ")])],1)]),_c('v-expansion-panel-content',[_c('div',[_c('ul',_vm._l((_vm.remainingTests.alarmQuantities),function(testType){return _c('li',{key:testType.medTestId,staticClass:"mb-1",class:{
                'success--text':
                  testType.remainingQuantity >= testType.alarmQuantity,
                'error--text':
                  testType.remainingQuantity < testType.alarmQuantity,
              }},[_vm._v(" "+_vm._s(testType.medTestName)+": "+_vm._s(testType.remainingQuantity)+" ")])}),0)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }