<template>
  <v-container class="component-wrapper d-flex flex-column">
    <div class="header text-center d-flex justify-center mb-8">
      <v-btn icon class="back-btn" @click="$router.go(-1)">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>

      <div class="text-h6">Test Inventory</div>
    </div>

    <v-text-field
      outlined
      dense
      label="Search"
      prepend-inner-icon="mdi-magnify"
      clearable
      hint="Search ship"
      persistent-hint
      class="flex-grow-0 mr-auto mb-5"
      :readonly="loader"
      :loading="loader"
      v-model="params.search"
    ></v-text-field>

    <div class="scrollable mb-5">
      <v-data-table
        :headers="headers"
        :items="!loader ? inventory.results : []"
        :loading="loader"
        hide-default-footer
        :server-items-length="inventory.rowCount"
        @update:options="onGetInventory"
        :options="options"
      >
        <template
          v-for="testType in testTypes"
          v-slot:[`item.alarmQuantities.${testType.itemKey}.remainingQuantity`]="{
            item,
          }"
        >
          <div
            :key="testType.itemKey"
            v-if="
              item &&
              item.alarmQuantities &&
              item.alarmQuantities[testType.itemKey]
            "
            :class="{
              'error--text':
                item.alarmQuantities[testType.itemKey].remainingQuantity <
                item.alarmQuantities[testType.itemKey].alarmQuantity,
              'success--text':
                item.alarmQuantities[testType.itemKey].remainingQuantity >=
                item.alarmQuantities[testType.itemKey].alarmQuantity,
            }"
          >
            {{ item.alarmQuantities[testType.itemKey].remainingQuantity }}
          </div>
        </template>

        <template v-slot:[`item.totalQuantity`]="{ item }">
          <div
            class="font-weight-bold"
            :class="{
              'error--text': item.totalQuantity < item.totalAlarm,
              'success--text': item.totalQuantity >= item.totalAlarm,
            }"
          >
            {{ item.totalQuantity }}
          </div>
        </template>
      </v-data-table>
    </div>

    <div class="pagination-wrapper">
      <v-pagination
        :length="inventory.pageCount"
        v-model="params.page"
        :disabled="loader"
        v-if="inventory.pageCount"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      headers: [{ text: "Ship", value: "shipName" }],
      loader: false,
      params: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        search: "",
      },
      timeout: null,
    };
  },
  computed: {
    ...mapState({
      inventory: (state) => state.shipowner.inventory,
      testTypes: (state) => state.base.lists.testTypes,
    }),
    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.params;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
  },
  created() {
    this.testTypes.forEach((testType) => {
      this.headers.push({
        text: testType.itemValue,
        value: `alarmQuantities.${testType.itemKey}.remainingQuantity`,
      });
    });

    this.headers.push({ text: "Total", value: "totalQuantity" });
  },
  methods: {
    ...mapActions(["getInventory"]),
    async onGetInventory(params) {
      if (this.loader) return;

      let { itemsPerPage, page, sortBy, sortDesc } = params || {};

      if (params) {
        this.params = {
          ...this.params,
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      }

      this.loader = true;

      try {
        await this.getInventory(this.params);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    "params.search"(newValue) {
      clearTimeout(this.timeout);

      if (!newValue) {
        this.onGetInventory({
          ...this.params,
          page: 1,
        });
      } else {
        this.timeout = setTimeout(() => {
          this.onGetInventory({
            ...this.params,
            page: 1,
          });
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>