var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"component-wrapper d-flex flex-column"},[_c('div',{staticClass:"header text-center d-flex justify-center mb-8"},[_c('v-btn',{staticClass:"back-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('div',{staticClass:"text-h6"},[_vm._v("Test List")])],1),_c('div',{staticClass:"d-flex mb-5"},[_c('v-text-field',{staticClass:"flex-grow-0 mr-auto",attrs:{"outlined":"","dense":"","label":"Search","prepend-inner-icon":"mdi-magnify","clearable":"","hint":"Search by first or last name","persistent-hint":"","readonly":_vm.loader,"loading":_vm.loader},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}}),_c('Filters',{attrs:{"fields":_vm.filterFields,"loader":_vm.loader},on:{"filterChange":_vm.onFilterChange}})],1),_c('div',{staticClass:"scrollable mb-5"},[_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"items":!_vm.loader ? _vm.tests.results : [],"loading":_vm.loader,"hide-default-footer":"","server-items-length":_vm.tests.rowCount,"options":_vm.options},on:{"update:options":_vm.onGetTests,"click:row":function($event){_vm.dialog = { open: true, item: $event }}},scopedSlots:_vm._u([{key:"item.onDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.onDate).format("DD/MM/YYYY"))+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:{
            'error--text': item.result == 'positive',
            'success--text': item.result == 'negative',
          }},[_vm._v(" "+_vm._s(item.result)+" ")])]}}],null,true)})],1),_c('div',{staticClass:"pagination-wrapper"},[(_vm.tests.pageCount)?_c('v-pagination',{attrs:{"length":_vm.tests.pageCount,"disabled":_vm.loader},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"800px","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open)?_c('TestDialog',{attrs:{"testId":_vm.dialog.item.medTestAppId},on:{"closeDialog":function($event){_vm.dialog = { open: false, item: null }}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }