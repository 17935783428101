import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth';
import base from './base';
import captain from './captain';
import shipowner from './shipowner';
import shared from './shared';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    base,
    captain,
    shipowner,
    shared
  }
})
