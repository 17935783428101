<template>
  <v-container class="component-wrapper d-flex flex-column">
    <div class="header text-center d-flex justify-center mb-8">
      <v-btn icon class="back-btn" @click="$router.go(-1)">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>

      <div class="text-h6">Transactions</div>
    </div>

    <RemainingTests class="mb-5" v-if="role == 'captain'" />

    <div class="d-flex align-center justify-space-between mb-5">
      <v-btn
        @click="dialog = true"
        color="primary "
        depressed
        v-if="role == 'captain'"
      >
        <v-icon color="white" left>mdi-plus</v-icon>
        New Test Transaction
      </v-btn>

      <Filters
        :fields="filterFields"
        :loader="loader"
        @filterChange="onFilterChange"
        class="ml-auto"
      />
    </div>

    <div class="scrollable mb-5">
      <v-data-table
        :headers="headers"
        :items="!loader ? transactions.results : []"
        :loading="loader"
        hide-default-footer
        :server-items-length="transactions.rowCount"
        @update:options="onGetTransactions"
        :options="options"
      >
        <template v-slot:[`item.onDate`]="{ item }">
          {{ dayjs(item.onDate).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:[`item.transactionTypeName`]="{ item }">
          <div
            class="font-weight-medium"
            :class="{
              'error--text': item.transactionTypeId == 'loss',
              'success--text': item.transactionTypeId == 'purch',
            }"
          >
            {{ item.transactionTypeName }}
          </div>
        </template>

        <template v-slot:[`item.quantity`]="{ item }">
          <div
            class="font-weight-medium"
            :class="{
              'error--text': item.transactionTypeId == 'loss',
              'success--text': item.transactionTypeId == 'purch',
            }"
          >
            {{ item.quantity }}
          </div>
        </template>

        <template v-slot:[`item.comments`]="{ item }">
          <v-tooltip bottom v-if="item.comments && item.comments.length > 10">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.comments.substr(0, 10) }}...
              </div>
            </template>

            <span>{{ item.comments }}</span>
          </v-tooltip>

          <div v-else>
            {{ item.comments ? item.comments : "-" }}
          </div>
        </template>
      </v-data-table>
    </div>

    <div class="pagination-wrapper">
      <v-pagination
        :length="transactions.pageCount"
        v-model="params.page"
        :disabled="loader"
        v-if="transactions.pageCount"
      ></v-pagination>
    </div>

    <v-dialog v-model="dialog" max-width="450px" persistent>
      <NewTransaction
        v-if="dialog"
        @closeDialog="dialog = false"
        @transactionAdded="onTransactionAdded"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import NewTransaction from "@/views/transactions/NewTransaction";
import Filters from "@/components/Filters";
import RemainingTests from "@/views/captain/RemainingTests";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    NewTransaction,
    Filters,
    RemainingTests,
  },
  data() {
    return {
      headers: [
        { text: "Transaction Date", value: "onDate" },
        {
          text: "Transaction Type",
          value: "transactionTypeName",
          sortable: false,
        },
        { text: "Test Type", value: "medTestName", sortable: false },
        { text: "Quantity", value: "quantity" },
        { text: "Master", value: "masterName" },
        { text: "Comments", value: "comments" },
      ],
      params: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        medTestId: null,
        transactionTypeId: null,
        ship: null,
        fleet: null,
      },
      dialog: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      transactions: (state) => state.shared.transactions,
      testTypes: (state) => state.base.lists.testTypes,
      transactionTypesAll: (state) => state.base.lists.transactionTypesAll,
      role: (state) => state.auth.role,
      ships: (state) => state.base.lists.ships,
      fleets: (state) => state.base.lists.fleets,
    }),
    filterFields() {
      const filters = [
        {
          key: "medTestId",
          value: "Test Type",
          values: this.testTypes,
          selectedValue: this.params.medTestId,
        },
        {
          key: "transactionTypeId",
          value: "Transaction Type",
          values: this.transactionTypesAll,
          selectedValue: this.params.transactionTypeId,
        },
      ];

      if (this.role == "shipowner") {
        filters.unshift({
          key: "ship",
          value: "Ship",
          values: this.ships,
          selectedValue: this.params.ship,
        });

        filters.unshift({
          key: "fleet",
          value: "Fleet",
          values: this.fleets,
          selectedValue: this.params.fleet,
        });
      }

      return filters;
    },
    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.params;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
  },
  created() {
    if (this.role == "shipowner") {
      this.headers.unshift({ text: "Ship", value: "shipName" });
      this.headers.unshift({ text: "Company/Group", value: "fleetName" });
    }
  },
  methods: {
    ...mapActions(["getTransactions", "getRemainingTests"]),
    async onGetTransactions(params) {
      if (this.loader) return;

      let { itemsPerPage, page, sortBy, sortDesc } = params || {};

      if (params) {
        this.params = {
          ...this.params,
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      }

      this.loader = true;

      try {
        await this.getTransactions(this.params);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onFilterChange(e) {
      this.params[e.field] = e.value;

      this.onGetTransactions({
        ...this.params,
        page: 1,
      });
    },
    onTransactionAdded() {
      this.params.medTestId = null;
      this.params.transactionTypeId = null;

      this.onGetTransactions({
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      });

      this.getRemainingTests();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>