import axios from 'axios';

export default {
    state: {
        remainingTests: null,
        remainingTestsLoader: false
    },
    mutations: {
        getRemainingTests(state, remainingTests) {
            state.remainingTests = remainingTests;
        },
        toggleRemainingTestsLoader(state, loader) {
            state.remainingTestsLoader = loader
        },
        clearCaptainState(state) {
            state.remainingTests = null;
        }
    },
    actions: {
        async newTest({ commit, dispatch }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/Ship/AddTestApplication`, { ...payload });

                dispatch("getRemainingTests");

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Test has been added!",
                    timeout: 5000
                })
            } catch (e) {
                if (e?.response?.data?.error?.errorCode != 20400) {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Something went wrong. Please try again later.",
                        timeout: 5000
                    })
                }

                throw e;
            }
        },
        async newTransaction({ commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/Ship/AddTransaction`, { ...payload });

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Transaction has been added!",
                    timeout: 5000
                })
            } catch (e) {
                if (e.response.data.error.errorCode != 20400) {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Something went wrong. Please try again later.",
                        timeout: 5000
                    })
                }

                throw e;
            }
        },
        async getRemainingTests({ commit }) {
            try {
                commit("toggleRemainingTestsLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/Ship/GetRemainingTests`);

                commit("getRemainingTests", res.data);

                commit("toggleRemainingTestsLoader", false);
            } catch (e) {
                if (e.response.status != 401) {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Something went wrong. Please try again later.",
                        timeout: 5000
                    })
                }

                throw e;
            }
        }
    }
}