<template>
  <div class="elevation-2">
    <v-container
      class="
        py-2
        px-4
        d-flex
        justify-space-between
        align-center
        toolbar-wrapper
      "
    >
      <img
        src="@/assets/logo.png"
        class="cursor-pointer"
        @click="$router.push({ path: '/' })"
      />

      <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" icon class="primary" v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <div class="white pa-5" v-if="info">
          <div class="d-flex">
            <div class="mr-5">
              <div class="body-2 font-weight-medium mb-2">Company:</div>
              <div
                class="body-2 font-weight-medium mb-2"
                v-if="role == 'captain'"
              >
                Ship:
              </div>
              <div class="body-2 font-weight-medium">Email:</div>
            </div>

            <div>
              <div class="body-2 mb-2">{{ info.clientName }}</div>
              <div class="body-2 mb-2" v-if="role == 'captain'">
                {{ info.shipName }}
              </div>
              <div class="body-2">{{ info.email }}</div>
            </div>
          </div>

          <v-divider class="my-5"></v-divider>

          <v-btn color="primary" depressed @click="onLogout" small>
            Log out
            <v-icon right> mdi-logout </v-icon>
          </v-btn>
        </div>
      </v-menu>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      info: (state) => state.base.info,
      role: (state) => state.auth.role,
    }),
  },
  methods: {
    ...mapActions(["logout"]),
    onLogout() {
      this.logout();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar-wrapper {
  height: 64px;

  img {
    height: 100%;
  }
}
</style>