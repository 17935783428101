<template>
  <div>
    <v-menu offset-y bottom left :close-on-content-click="false" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          color="primary"
          left
          :value="Boolean(countFilters)"
          :content="countFilters"
          class="flex-grow-1"
          overlap
        >
          <v-btn depressed v-bind="attrs" v-on="on" width="100%">
            <v-icon left>mdi-filter</v-icon>
            Filter
          </v-btn>
        </v-badge>
      </template>

      <div class="white pa-5">
        <div v-for="field in fields" :key="field.key">
          <v-select
            outlined
            dense
            hide-details
            class="mb-2"
            :label="field.value"
            :items="field.values"
            item-value="itemKey"
            item-text="itemValue"
            clearable
            @change="onFilterChange($event, field.key)"
            :readonly="loader"
            :value="field.selectedValue"
          ></v-select>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["fields", "loader"],
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    countFilters() {
      return this.fields.filter((field) => field.selectedValue).length;
    },
  },
  methods: {
    onFilterChange(value, field) {
      this.$emit("filterChange", {
        value,
        field,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>