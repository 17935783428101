<template>
  <v-container class="component-wrapper d-flex flex-column">
    <div class="header text-center d-flex justify-center mb-7">
      <v-btn icon class="back-btn" @click="$router.go(-1)">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>

      <div class="text-h6">New Test</div>
    </div>

    <form @submit.prevent="submit" class="flex-grow-1 d-flex flex-column">
      <div class="scrollable mb-5 pt-1">
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="6"
            lg="5"
            xl="4"
            offset="0"
            offset-lg="1"
            offset-xl="2"
            class="px-5"
            :class="{ 'mb-10': $vuetify.breakpoint.xsOnly }"
          >
            <v-text-field
              outlined
              dense
              class="mb-1"
              label="First Name *"
              v-model="firstName"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              class="mb-1"
              label="Last Name *"
              v-model="lastName"
            ></v-text-field>

            <v-select
              outlined
              dense
              class="mb-1"
              label="Rank *"
              :items="ranks"
              item-value="itemKey"
              item-text="itemValue"
              v-model="rank"
            ></v-select>

            <v-select
              outlined
              dense
              class="mb-1"
              label="Gender"
              v-model="gender"
              :items="genders"
              item-value="itemKey"
              item-text="itemValue"
            ></v-select>

            <v-select
              outlined
              dense
              class="mb-1"
              label="Nationality *"
              v-model="nationality"
              :items="countries"
              item-value="itemKey"
              item-text="itemValue"
            ></v-select>

            <v-select
              outlined
              dense
              class="mb-1"
              label="Test Type *"
              v-model="testType"
              :items="testTypes"
              item-value="itemKey"
              item-text="itemValue"
              :error-messages="error ? 'There are no tests available' : ''"
              @change="error = false"
            ></v-select>

            <div class="d-flex mb-4">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedDate"
                    dense
                    prepend-inner-icon="mdi-calendar"
                    hide-details
                    outlined
                    label="Test Date"
                    class="mr-4"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="date"
                  @change="dateMenu = false"
                ></v-date-picker>
              </v-menu>

              <v-menu
                v-model="timeMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="time"
                    dense
                    prepend-inner-icon="mdi-clock"
                    hide-details
                    outlined
                    label="Test Time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-time-picker
                  v-model="time"
                  format="24hr"
                  @change="timeMenu = false"
                ></v-time-picker>
              </v-menu>
            </div>

            <v-radio-group
              label="Test Result *"
              dense
              hide-details
              class="ma-0"
              v-model="testResult"
            >
              <v-radio label="Positive" value="positive"></v-radio>

              <v-radio label="Negative" value="negative"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6" lg="5" xl="4" class="px-5">
            <div
              v-for="(question, index) in questionnaire.results"
              :key="question.symptomTypeId"
              :class="{
                'mb-5': index < questionnaire.results.length - 1,
              }"
            >
              <div class="subtitle-1 font-weight-bold mb-3">
                {{ question.symptomTypeText }}
              </div>

              <div
                v-for="(answer, index) in question.allSymptoms"
                :key="answer.symptomId"
                class="d-flex flex-column align-start"
              >
                <v-checkbox
                  :label="answer.symptomText"
                  :value="answer.symptomId"
                  v-model="positiveSymptomIds"
                  dense
                  hide-details
                  class="ma-0 pa-0"
                  :class="{
                    'mb-3': index < question.allSymptoms.length - 1,
                  }"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="mt-auto mx-auto">
        <v-btn
          class="mr-4"
          large
          color="error"
          depressed
          @click="$router.go(-1)"
          >Cancel</v-btn
        >

        <v-btn
          large
          color="success"
          depressed
          type="submit"
          :loading="loader"
          :disabled="$v.$invalid"
          >Submit</v-btn
        >
      </div>
    </form>

    <!-- ====== CLOSE DIALOG ====== -->
    <v-dialog v-model="dialog" max-width="450px">
      <div class="white pa-4 d-flex flex-column align-center">
        <div class="subtitle-1 font-weight-medium text-center mb-1">
          You have unsaved changes
        </div>

        <div class="body-2 text-center mb-3">
          Are you sure you want to leave? All unsaved changes will be lost if
          you leave this page.
        </div>

        <v-btn
          class="mt-4 mb-2"
          depressed
          color="primary"
          @click="dialog = false"
          >Stay on this page</v-btn
        >

        <v-btn
          class="standard-size"
          color="error"
          text
          @click="
            allowLeave = true;
            $router.go(-1);
          "
          >Discard changes and leave</v-btn
        >
      </div>
    </v-dialog>
  </v-container>
</template>
 
<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      rank: null,
      gender: null,
      nationality: null,
      testType: null,
      dateMenu: false,
      date: new Date().toISOString().substr(0, 10),
      timeMenu: false,
      time: this.dayjs(new Date()).format("HH:mm"),
      testResult: null,
      positiveSymptomIds: [],
      initialData: null,
      allowLeave: false,
      dialog: false,
      loader: false,
      error: false,
    };
  },
  validations: {
    firstName: { required },
    lastName: { required },
    rank: { required },
    nationality: { required },
    testType: { required },
    date: { required },
    time: { required },
    testResult: { required },
  },
  computed: {
    ...mapState({
      ranks: (state) => state.base.lists.ranks,
      testTypes: (state) => state.base.lists.testTypes,
      genders: (state) => state.base.lists.genders,
      countries: (state) => state.base.lists.countries,
      questionnaire: (state) => state.base.questionnaire,
    }),
    formattedDate() {
      return this.date ? this.dayjs(this.date).format("DD/MM/YYYY") : "";
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !==
        JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          rank: this.rank,
          gender: this.gender,
          nationality: this.nationality,
          testType: this.testType,
          date: this.date,
          time: this.time,
          testResult: this.testResult,
        })
      );
    },
  },
  created() {
    this.initialData = {
      firstName: this.firstName,
      lastName: this.lastName,
      rank: this.rank,
      gender: this.gender,
      nationality: this.nationality,
      testType: this.testType,
      date: this.date,
      time: this.time,
      testResult: this.testResult,
    };
  },
  methods: {
    ...mapActions(["newTest"]),
    async submit() {
      this.error = false;
      this.loader = true;

      try {
        await this.newTest({
          firstName: this.firstName,
          lastName: this.lastName,
          crewRankId: this.rank,
          genderId: this.gender,
          nationalityId: this.nationality,
          medTestId: this.testType,
          onDate: this.dayjs.utc(`${this.date} ${this.time}`).toISOString(),
          result: this.testResult,
          positiveSymptomIds: this.positiveSymptomIds,
        });

        this.allowLeave = true;

        this.$router.push({ path: "/captain" });
      } catch (e) {
        console.log(e?.response?.data?.error?.errorCode);

        if (e?.response?.data?.error?.errorCode == 20400) this.error = true;

        console.log(e);
      }

      this.loader = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges && !this.allowLeave) {
      this.dialog = true;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>