<template>
  <div class="component-wrapper d-flex flex-column justify-center align-center">
    <form
      @submit.prevent="submit"
      novalidate
      class="form d-flex flex-column pa-5 pt-10"
      :class="{ 'elevation-2': $vuetify.breakpoint.smAndUp }"
    >
      <div class="rounded-circle primary align-self-center pa-2 form-icon">
        <v-icon x-large color="white">mdi-key</v-icon>
      </div>

      <div class="text-center primary--text mb-5">
        <div class="text-h5 mb-2">Forgot password?</div>
        <div class="body-2">
          Don’t worry. Enter your email and we will send you instructions for
          creating a new password.
        </div>
      </div>

      <v-text-field
        outlined
        dense
        label="Email"
        prepend-inner-icon="mdi-account"
        v-model="email"
        type="email"
        :error-messages="emailErrors"
        class="mb-1"
      ></v-text-field>

      <v-btn
        type="submit"
        depressed
        color="primary"
        class="mb-5"
        :disabled="!$v.email.required"
        >Send recovery email</v-btn
      >

      <div
        class="body-2 text-center link"
        @click="$router.push({ path: '/login' })"
      >
        Back to login
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      email: null,
    };
  },
  validations: {
    email: { required, email },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.email) errors.push("Invalid email format");
      return errors;
    },
  },
  methods: {
    ...mapActions(["forgotPassword"]),
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        await this.forgotPassword({
          email: this.email,
        });

        this.$router.push({ path: "/login" });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 450px;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  .form-icon {
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>