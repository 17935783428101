<template>
  <div class="component-wrapper d-flex flex-column justify-center align-center">
    <form
      @submit.prevent="submit"
      novalidate
      class="form d-flex flex-column pa-5 pt-10"
      :class="{ 'elevation-2': $vuetify.breakpoint.smAndUp }"
    >
      <div class="rounded-circle primary align-self-center pa-2 form-icon">
        <v-icon x-large color="white">mdi-account</v-icon>
      </div>

      <div class="text-center primary--text mb-5">
        <div class="text-h5 mb-2">Healthpass Next2Me</div>
        <div class="body-2">Login</div>
      </div>

      <v-text-field
        outlined
        dense
        label="Username"
        prepend-inner-icon="mdi-account"
        v-model="username"
        type="email"
        :error="error"
        class="mb-1"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Password"
        prepend-inner-icon="mdi-lock"
        v-model="password"
        type="password"
        :error-messages="error ? 'Wrong credentials' : ''"
        class="mb-1"
      ></v-text-field>

      <v-btn
        type="submit"
        depressed
        color="primary"
        class="mb-5"
        :disabled="!username || !password"
        :loading="loader"
        >Login</v-btn
      >

      <div
        class="body-2 text-center link"
        @click="$router.push({ path: '/forgot-password' })"
      >
        Forgot your password?
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      username: null,
      password: null,
      error: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.role,
    }),
  },
  methods: {
    ...mapActions(["login"]),
    async submit() {
      this.error = false;

      try {
        this.loader = true;

        const role = await this.login({
          userName: this.username,
          userPassword: this.password,
        });

        if (role == "captain") {
          this.$router.push({ path: "/captain" });
        } else if (role == "shipowner") {
          this.$router.push({ path: "/shipowner" });
        }
      } catch (e) {
        this.error = true;
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 450px;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  .form-icon {
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>