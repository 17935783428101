import axios from "axios";

export default {
    state: {
        snackbar: {
            open: false,
            text: null,
            color: null,
            timeout: null
        },
        lists: {
            ranks: [],
            testTypes: [],
            genders: [],
            countries: [],
            transactionTypes: [],
            transactionTypesAll: [],
            ships: [],
            fleets: []
        },
        info: null,
        questionnaire: [],
        banner: null
    },
    mutations: {
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar
            };
        },
        getLists(state, list) {
            state.lists[list.key] = list.value;
        },
        getInfo(state, info) {
            state.info = info;
        },
        getQuestionnaire(state, questionnaire) {
            state.questionnaire = questionnaire;
        },
        getBanner(state, banner) {
            state.banner = banner;
        },
        clearBaseState(state) {
            state.lists.ships = [];
            state.info = null;
        }
    },
    actions: {
        async init({ rootGetters, dispatch, commit, rootState }) {
            try {
                if (rootGetters.isLoggedIn) {
                    const token = rootState.auth.token;
                    delete axios.defaults.headers.common['Authorization'];
                    await dispatch("getBanner");
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                } else {
                    dispatch("getBanner");
                }

                const promises = [
                    dispatch('getLists', 'CrewRanks'),
                    dispatch('getLists', 'TestTypes'),
                    dispatch('getLists', 'Genders'),
                    dispatch('getLists', 'Nationalities'),
                    dispatch('getLists', 'TransactionTypes'),
                    dispatch('getLists', 'TransactionTypesAll'),
                    dispatch("getQuestionnaire"),
                ];

                if (rootGetters.isLoggedIn)
                    promises.push(dispatch('getInfo'))

                if (rootState.auth.role == 'captain')
                    promises.push(dispatch('getRemainingTests'))

                if (rootState.auth.role == 'shipowner') {
                    promises.push(dispatch('getShips'))
                    promises.push(dispatch('getFleets'))
                }

                const res = await Promise.all(promises);

                commit("getLists", { key: 'ranks', value: res[0].data.results });
                commit("getLists", { key: 'testTypes', value: res[1].data.results });
                commit("getLists", { key: 'genders', value: res[2].data.results });
                commit("getLists", { key: 'countries', value: res[3].data.results });
                commit("getLists", { key: 'transactionTypes', value: res[4].data.results });
                commit("getLists", { key: 'transactionTypesAll', value: res[5].data.results });

            } catch (e) {
                throw e;
            }
        },
        async getLists({ }, listName) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/System/GetListItems/${listName}`);

                return res;
            } catch (e) {
                throw e;
            }
        },
        async getInfo({ commit, rootState }) {
            const role = rootState.auth.role;

            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/${role == 'shipowner' ? 'ClientAdmin' : 'Ship'}/GetInfo`);

                commit("getInfo", res.data);
            } catch (e) {
                throw e;
            }
        },
        async getShips({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/ClientAdmin/GetVesselList`);

                commit("getLists", { key: 'ships', value: res.data.results });
            } catch (e) {
                throw e;
            }
        },
        async getFleets({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/ClientAdmin/GetFleetList`);

                commit("getLists", { key: 'fleets', value: res.data.results });
            } catch (e) {
                throw e;
            }
        },
        async getQuestionnaire({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/system/GetSymptomTypes`);

                commit("getQuestionnaire", res.data);
            } catch (e) {
                throw e;
            }
        },
        async getBanner({ commit }) {
            try {
                const res = await axios.get('https://healthnewsnext2me.dn2me.com/restapi/latestshipcarearticle');

                const title = res.data[0].title;
                const link = res.data[0].link;
                const image = `https://healthnewsnext2me.dn2me.com/sites/default/files/${res.data[0].Image.uri.split("public://")[1]}`;

                commit("getBanner", {
                    title,
                    link,
                    image
                });
            } catch (e) {
                console.log(e);
            }
        }
    },
}
