import axios from 'axios';

export default {
    state: {
        token: localStorage.getItem('token') || null,
        role: localStorage.getItem('role') || null
    },
    getters: {
        isLoggedIn: state => !!state.token
    },
    mutations: {
        login(state, user) {
            state.token = user.token;
            state.role = user.role;
        },
        logout(state) {
            state.token = null;
            state.role = null;
        }
    },
    actions: {
        async login({ commit, dispatch }, credentials) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_HEALTHPASS_BASE_URL}/User/login`, credentials);

                const token = res.data.jwt;
                let role;

                if (res.data.userRole == 'vessel') role = 'captain';
                if (res.data.userRole == 'manager') role = 'shipowner';

                localStorage.setItem('token', token);
                localStorage.setItem('role', role);

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

                commit('login', {
                    token,
                    role
                });

                dispatch('getInfo');

                if (role == 'captain')
                    dispatch('getRemainingTests')

                if (role == 'shipowner') {
                    dispatch('getShips')
                    dispatch('getFleets')
                }

                return role;
            } catch (e) {
                throw e;
            }
        },
        async logout({ commit }) {
            //clear state
            commit('clearBaseState')
            commit("clearCaptainState");
            commit("clearShipownerState");
            commit("clearSharedState");

            commit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            delete axios.defaults.headers.common['Authorization'];
        },
        async forgotPassword({ commit }) {
            commit("toggleSnackbar", {
                open: true,
                color: "success",
                text: "Email has been sent.",
                timeout: 5000
            })
        }
    }
}