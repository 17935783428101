import Shipowner from '@/views/shipowner/Shipowner.vue'
import TestList from '@/views/test-list/TestList.vue'
import Inventory from '@/views/shipowner/Inventory.vue'
import Transactions from '@/views/transactions/Transactions.vue'

export default [
    {
        path: '/shipowner',
        component: Shipowner,
        meta: { requiresAuth: true, role: 'shipowner' }
    },
    {
        path: '/shipowner/test-list',
        component: TestList,
        meta: { requiresAuth: true, role: 'shipowner' }
    },
    {
        path: '/shipowner/inventory',
        component: Inventory,
        meta: { requiresAuth: true, role: 'shipowner' }
    },
    {
        path: '/shipowner/transactions',
        component: Transactions,
        meta: { requiresAuth: true, role: 'shipowner' }
    },
]