<template>
  <div class="white dialog-wrapper scrollable-dialog">
    <div class="primary pa-5 header">
      <v-btn icon color="white" class="back-btn" @click="$emit('closeDialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <div class="text-h6 white--text text-center">Test Details</div>
    </div>

    <v-progress-circular
      size="120"
      width="5"
      indeterminate
      color="primary"
      class="ma-auto"
      v-if="loader"
    ></v-progress-circular>

    <template v-else>
      <div class="pa-5 scrollable">
        <v-row>
          <v-col cols="12" sm="6">
            <div class="body-1 mb-2">
              Name:
              {{ testData.firstName }} {{ testData.lastName }}
            </div>

            <div class="body-1 mb-2">Rank: {{ testData.crewRankName }}</div>

            <div class="body-1 mb-2" v-if="testData.genderName">
              Gender: {{ testData.genderName }}
            </div>

            <div class="body-1 mb-2" v-if="testData.genderName">
              Nationality: {{ testData.nationalityName }}
            </div>

            <div class="body-1 mb-2">Test Type: {{ testData.medTestName }}</div>

            <div class="body-1 mb-2">
              Test Date: {{ dayjs(testData.onDate).format("DD/MM/YYYY HH:mm") }}
            </div>

            <div class="d-flex">
              <div class="body-1 mr-1">Result:</div>

              <div
                class="body-1 font-weight-medium"
                :class="{
                  'red--text': testData.result == 'positive',
                  'green--text': testData.result == 'negative',
                }"
              >
                {{ testData.result }}
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div
              v-for="(question, index) in questionnaire.results"
              :key="question.symptomTypeId"
              :class="{
                'mb-5': index < questionnaire.results.length - 1,
              }"
            >
              <div class="subtitle-1 font-weight-bold mb-3">
                {{ question.symptomTypeText }}
              </div>

              <div
                v-for="(answer, index) in question.allSymptoms"
                :key="answer.symptomId"
                class="d-flex flex-column align-start"
              >
                <v-checkbox
                  :label="answer.symptomText"
                  :value="answer.symptomId"
                  :input-value="testData.positiveSymptomIds"
                  readonly
                  :ripple="false"
                  dense
                  hide-details
                  class="ma-0 pa-0"
                  :class="{
                    'mb-3': index < question.allSymptoms.length - 1,
                  }"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="pa-3 align-self-center d-flex justify-center">
        <v-btn depressed color="primary" @click="$emit('closeDialog')"
          >Close</v-btn
        >
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["testId"],
  data() {
    return {
      loader: false,
      testData: null,
    };
  },
  computed: {
    ...mapState({
      questionnaire: (state) => state.base.questionnaire,
    }),
  },
  async created() {
    this.loader = true;

    try {
      this.testData = await this.getTest(this.testId);

      this.loader = false;
    } catch (e) {
      console.log(e);
      this.$emit("closeDialog");
    }
  },
  methods: {
    ...mapActions(["getTest"]),
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  left: 20px;
}
</style>