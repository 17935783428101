<template>
  <div class="component-wrapper pt-12">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <div
            class="
              primary
              d-flex
              flex-column
              align-center
              white--text
              rounded
              pa-5
              card
            "
            @click="$router.push({ path: '/shipowner/test-list' })"
          >
            <v-icon x-large color="white" class="mb-5"
              >mdi-format-list-numbered</v-icon
            >

            <div class="text-h6">Test List</div>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div
            class="
              primary
              d-flex
              flex-column
              align-center
              white--text
              rounded
              pa-5
              card
            "
            @click="$router.push({ path: '/shipowner/inventory' })"
          >
            <v-icon x-large color="white" class="mb-5"
              >mdi-clipboard-text</v-icon
            >

            <div class="text-h6">Test Inventory</div>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div
            class="
              primary
              d-flex
              flex-column
              align-center
              white--text
              rounded
              pa-5
              card
            "
            @click="$router.push({ path: '/shipowner/transactions' })"
          >
            <v-icon x-large color="white" class="mb-5"
              >mdi-wallet-travel</v-icon
            >

            <div class="text-h6">Transactions</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>