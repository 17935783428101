<template>
  <v-container class="component-wrapper d-flex flex-column">
    <div class="header text-center d-flex justify-center mb-8">
      <v-btn icon class="back-btn" @click="$router.go(-1)">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>

      <div class="text-h6">Test List</div>
    </div>

    <div class="d-flex mb-5">
      <v-text-field
        outlined
        dense
        label="Search"
        prepend-inner-icon="mdi-magnify"
        clearable
        hint="Search by first or last name"
        persistent-hint
        class="flex-grow-0 mr-auto"
        :readonly="loader"
        :loading="loader"
        v-model="params.search"
      ></v-text-field>

      <Filters
        :fields="filterFields"
        :loader="loader"
        @filterChange="onFilterChange"
      />
    </div>

    <div class="scrollable mb-5">
      <v-data-table
        :headers="headers"
        :items="!loader ? tests.results : []"
        :loading="loader"
        hide-default-footer
        class="clickable"
        :server-items-length="tests.rowCount"
        @update:options="onGetTests"
        @click:row="dialog = { open: true, item: $event }"
        :options="options"
      >
        <template v-slot:[`item.onDate`]="{ item }">
          {{ dayjs(item.onDate).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:[`item.result`]="{ item }">
          <span
            class="font-weight-bold"
            :class="{
              'error--text': item.result == 'positive',
              'success--text': item.result == 'negative',
            }"
          >
            {{ item.result }}
          </span>
        </template>
      </v-data-table>
    </div>

    <div class="pagination-wrapper">
      <v-pagination
        :length="tests.pageCount"
        v-model="params.page"
        :disabled="loader"
        v-if="tests.pageCount"
      ></v-pagination>
    </div>

    <v-dialog
      v-model="dialog.open"
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <TestDialog
        v-if="dialog.open"
        :testId="dialog.item.medTestAppId"
        @closeDialog="dialog = { open: false, item: null }"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Filters from "@/components/Filters";
import TestDialog from "@/views/test-list/TestDialog";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Filters,
    TestDialog,
  },
  data() {
    return {
      headers: [
        { text: "Test Date", value: "onDate" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Test Type", value: "medTestName", sortable: false },
        { text: "Result", value: "result", sortable: false },
      ],
      loader: false,
      params: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        search: "",
        medTestId: null,
        result: null,
        ship: null,
        fleet: null,
      },
      timeout: null,
      dialog: {
        open: false,
        item: null,
      },
    };
  },
  computed: {
    ...mapState({
      testTypes: (state) => state.base.lists.testTypes,
      tests: (state) => state.shared.tests,
      role: (state) => state.auth.role,
      ships: (state) => state.base.lists.ships,
      fleets: (state) => state.base.lists.fleets,
    }),
    filterFields() {
      const filters = [
        {
          key: "medTestId",
          value: "Test Type",
          values: this.testTypes,
          selectedValue: this.params.medTestId,
        },
        {
          key: "result",
          value: "Test Result",
          values: ["positive", "negative"],
          selectedValue: this.params.result,
        },
      ];

      if (this.role == "shipowner") {
        filters.unshift({
          key: "ship",
          value: "Ship",
          values: this.ships,
          selectedValue: this.params.ship,
        });

        filters.unshift({
          key: "fleet",
          value: "Fleet",
          values: this.fleets,
          selectedValue: this.params.fleet,
        });
      }

      return filters;
    },
    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.params;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
  },
  created() {
    if (this.role == "shipowner") {
      this.headers.unshift({ text: "Ship", value: "shipName" });
      this.headers.unshift({ text: "Company/Group", value: "fleetName" });
    }
  },
  methods: {
    ...mapActions(["getTests"]),
    async onGetTests(params) {
      if (this.loader) return;

      let { itemsPerPage, page, sortBy, sortDesc } = params || {};

      if (params) {
        this.params = {
          ...this.params,
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      }

      this.loader = true;

      try {
        await this.getTests(this.params);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onFilterChange(e) {
      this.params[e.field] = e.value;

      this.onGetTests({
        ...this.params,
        page: 1,
      });
    },
  },
  watch: {
    "params.search"(newValue) {
      clearTimeout(this.timeout);

      if (!newValue) {
        this.onGetTests({
          ...this.params,
          page: 1,
        });
      } else {
        this.timeout = setTimeout(() => {
          this.onGetTests({
            ...this.params,
            page: 1,
          });
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>