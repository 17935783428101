var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"component-wrapper d-flex flex-column"},[_c('div',{staticClass:"header text-center d-flex justify-center mb-8"},[_c('v-btn',{staticClass:"back-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('div',{staticClass:"text-h6"},[_vm._v("Transactions")])],1),(_vm.role == 'captain')?_c('RemainingTests',{staticClass:"mb-5"}):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-space-between mb-5"},[(_vm.role == 'captain')?_c('v-btn',{attrs:{"color":"primary ","depressed":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Test Transaction ")],1):_vm._e(),_c('Filters',{staticClass:"ml-auto",attrs:{"fields":_vm.filterFields,"loader":_vm.loader},on:{"filterChange":_vm.onFilterChange}})],1),_c('div',{staticClass:"scrollable mb-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":!_vm.loader ? _vm.transactions.results : [],"loading":_vm.loader,"hide-default-footer":"","server-items-length":_vm.transactions.rowCount,"options":_vm.options},on:{"update:options":_vm.onGetTransactions},scopedSlots:_vm._u([{key:"item.onDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.onDate).format("DD/MM/YYYY"))+" ")]}},{key:"item.transactionTypeName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium",class:{
            'error--text': item.transactionTypeId == 'loss',
            'success--text': item.transactionTypeId == 'purch',
          }},[_vm._v(" "+_vm._s(item.transactionTypeName)+" ")])]}},{key:"item.quantity",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium",class:{
            'error--text': item.transactionTypeId == 'loss',
            'success--text': item.transactionTypeId == 'purch',
          }},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.comments",fn:function(ref){
          var item = ref.item;
return [(item.comments && item.comments.length > 10)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.comments.substr(0, 10))+"... ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comments))])]):_c('div',[_vm._v(" "+_vm._s(item.comments ? item.comments : "-")+" ")])]}}],null,true)})],1),_c('div',{staticClass:"pagination-wrapper"},[(_vm.transactions.pageCount)?_c('v-pagination',{attrs:{"length":_vm.transactions.pageCount,"disabled":_vm.loader},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"450px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('NewTransaction',{on:{"closeDialog":function($event){_vm.dialog = false},"transactionAdded":_vm.onTransactionAdded}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }